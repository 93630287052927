<template>
  <v-menu offset-x left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small icon v-bind="attrs" v-on="on"
        ><v-icon>mdi-dots-vertical</v-icon></v-btn
      >
    </template>
    <v-list dense>
      <v-list-item @click="modificarDatos()">
        <v-list-item-title>Modificar datos</v-list-item-title>
      </v-list-item>
      <v-divider class="mx-3"></v-divider>
      <v-list-item @click="asignarPropietario()">
        <v-list-item-title>Asignar propietario</v-list-item-title>
      </v-list-item>
      <v-divider class="mx-3"></v-divider>
      <v-list-item @click="asignarSuplente()">
        <v-list-item-title>Asignar suplente</v-list-item-title>
      </v-list-item>
      <div v-if="this.consejero.suplente.nombre">
        <v-divider class="mx-3"></v-divider>
        <v-list-item @click="eliminarSuplente()">
          <v-list-item-title>Eliminar suplente</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "consejeroMenu",
  props: ["consejero"],
  methods: {
    modificarDatos() {
      const emitData = {
        idConsejero: this.consejero._id,
        dependencia: this.consejero.idDependencia,
        divisionDepto: this.consejero.divisionDepto,
      };
      this.$emit("modificarDatos", emitData);
    },
    asignarPropietario() {
      this.$emit("asignarPropietario", this.consejero._id);
    },
    asignarSuplente() {
      this.$emit("asignarSuplente", this.consejero._id);
    },
    eliminarSuplente() {
      const emitData = {
        idConsejero: this.consejero._id,
        suplente: this.consejero.suplente,
      };
      this.$emit("eliminarSuplente", emitData);
    },
  },
};
</script>

<style>
.avatar-item {
  margin-right: 15px !important;
}
</style>
