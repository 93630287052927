<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dark dense flat
        ><h3>Modificar consejero {{ tipo }}</h3></v-toolbar
      >
      <v-container>
        <v-row no-gutters>
          <v-col cols="5">
            <v-text-field
              v-model="codigo"
              name="SG-codigo"
              label="Código"
              type="text"
              :loading="loadingCodigo"
              :disabled="loading || loadingCodigo"
              color="blue-grey"
              outlined
              dense
              :error-messages="codigoErrors"
              @input="validarCodigo()"
              @blur="buscarUsuario()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="nombre"
              name="SG-nombre"
              label="Nombre"
              type="text"
              readonly
              color="blue-grey"
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="categoria"
              name="SG-categoria"
              label="Categoria"
              type="text"
              :disabled="blockCategoria"
              color="blue-grey"
              outlined
              dense
              :error-messages="categoriaErrors"
              @input="validarCategoria()"
              @blur="validarCategoria()"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          small
          :class="{ 'disable-events': blockAceptar }"
          @click="modificarUsuario()"
          >Asignar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

import {
  getUsusarioByCodigo,
  modificarConsejeroService,
} from "./consejos.service";

export default {
  name: "modificarConsejero",
  props: ["mostrar", "idConsejero", "tipo"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["userAdmin"]),
    blockAceptar() {
      if (this.loading || this.loadingCodigo) return true;
      if (this.codigoErrors.length > 0) return true;
      if (!this.categoria || this.categoriaErrors.length > 0) return true;
      if (this.nombre.length == 0) return true;
      return false;
    },
    blockCategoria() {
      if (this.loading || this.loadingCodigo) return true;
      if (!this.codigo || this.codigoErrors.length > 0) return true;
      return false;
    },
  },
  validations: {
    codigo: { required, minLength: minLength(5) },
    categoria: { required },
  },
  data() {
    return {
      loading: false,
      loadingCodigo: false,
      codigo: "",
      nombre: "",
      categoria: "",
      codigoErrors: [],
      categoriaErrors: [],
    };
  },
  methods: {
    validarCodigo() {
      this.$v.codigo.$touch();
      this.codigoErrors = [];
      !this.$v.codigo.required && this.codigoErrors.push("Campo requerido.");
      !this.$v.codigo.minLength &&
        this.codigoErrors.push("Mínimo 5 caracteres.");
      if (this.codigoErrors.length > 0) this.nombre = "";
    },
    validarCategoria() {
      this.$v.categoria.$touch();
      this.categoriaErrors = [];
      !this.$v.categoria.required &&
        this.categoriaErrors.push("Campo requerido.");
    },
    async buscarUsuario() {
      if (this.codigo) {
        try {
          this.loadingCodigo = true;
          const sendData = { codigo: this.codigo };
          const serverResponse = await getUsusarioByCodigo(sendData);
          this.loadingCodigo = false;
          this.$validateResponse(serverResponse);
          if (!serverResponse.ok) {
            this.codigoErrors.push(serverResponse.mensaje);
            this.nombre = "";
          } else this.nombre = serverResponse.usuario.nombre;
        } catch (error) {
          this.loadingCodigo = false;
          this.$notificarError(error);
        }
      }
    },
    async modificarUsuario() {
      try {
        this.loading = true;
        const sendData = {
          idConsejero: this.idConsejero,
          tipo: this.tipo,
          codigo: this.codigo,
          categoria: this.categoria,
        };
        const serverResponse = await modificarConsejeroService(sendData);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          if (serverResponse.codigoError)
            this.codigoErrors.push(serverResponse.mensaje);
          else throw new Error(serverResponse.mensaje);
        } else {
          const emitData = {
            ...sendData,
            usuarioMaster: serverResponse.usuarioMaster,
          };
          this.$emit("consejeroModificado", emitData);
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
