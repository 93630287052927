<template>
    <v-dialog v-model="mostrar" persistent max-width="500">
        <v-card>
            <v-toolbar dense color="red darken-4" dark flat class="text-h6">Eliminar suplente</v-toolbar>
            <v-container grid-list-md>
                <p><b>¿Está seguro de eliminar al suplente?</b> <br>
                <i>Esta acción no podrá ser revertida.</i> </p>
                <ul><li>{{suplente.nombre}}</li></ul>
                <ul><li>{{suplente.categoria}}</li></ul>
            </v-container>
            <v-divider class="mx-3"></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" 
                        small 
                        outlined 
                        :disabled="loading" 
                        @click="$emit('cancelar')">Cancelar</v-btn>
                        <v-btn
                        small
                        dark
                        color="red darken-4"
                        :loading="loading" 
                        @click="eliminar()">Eliminar</v-btn>
                    </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { eliminarSuplenteService } from "./consejos.service";

export default {
  name: "eliminarSuplente",
  props: {
    mostrar: Boolean,
    idConsejero: String,
    suplente: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async eliminar() {
        try {
            this.loading = true;
            const serverResponse = await eliminarSuplenteService(this.idConsejero);
            this.loading = false;
            this.$validateResponse(serverResponse);
            if (!serverResponse.ok) {
                throw new Error(serverResponse.mensaje);
            } else {
                this.$notificarSuccess('Suplente eliminado correctamente')
                const emitData = {
                  idConsejero: this.idConsejero,
                  tipo: 'suplente',
                  categoria: null,
                  usuarioMaster: null,
                }
                this.$emit("consejeroModificado", emitData);
            }
            this.$emit("cancelar");

        } catch (error) {
            this.loading = false;
            this.$notificarError(error);
        }
      
    },
  },
};
</script>