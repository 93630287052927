<template>
  <v-list-item v-if="consejero.nombre" style="padding-left: 0px;">
    <v-list-item-icon class="avatar-item">
      <v-icon size="40">mdi-account-circle</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        {{ consejero.nombre.toUpperCase() }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ consejero.categoria }}
      </v-list-item-subtitle>
       <v-list-item-subtitle>
        {{ consejero.codigo }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ consejero.correo? consejero.correo : "SIN CORREO REGISTRADO EN MÁSTER" }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "consejeroItem",
  props: ["consejero"],
};
</script>

<style>
.avatar-item{
    margin-right: 15px !important;
}
</style>
