<template>
    <div class="pa-5">
        <v-dialog v-model="dialog" max-width="800px">
            <v-card :loading="loading">
                <v-toolbar color="primary" dark style="display: flex;justify-content: center;flex: 1">
                    <h3>{{ hayCorreo ? "MODIFICAR" : "AÑADIR" }} CORREO</h3>
                </v-toolbar>
                <v-container class="pa-5">
                    <v-form v-model="valido">
                        <span class="subtitle-2 grey--text pl-1">Asunto</span>
                        <v-text-field outlined required :rules="reglasVtext" v-model="correo.asunto"></v-text-field>
                        <span class="subtitle-2 grey--text pl-1">Cuerpo</span>
                        <vueEditor v-model="correo.cuerpo" :editorToolbar="customToolbar"></vueEditor>
                        <div class="pt-6">
                            <span class="subtitle-2 grey--text pl-1">Despedida</span>
                            <v-text-field outlined required :rules="reglasVtext" v-model="correo.despedida"></v-text-field>
                        </div>
                    </v-form>
                    <v-row no-gutters justify="end" style="gap: 10px;">
                        <v-btn small color="primary" :disabled="!valido || correo.cuerpo == '' || !liga"
                            @click="modificarCorreoConsejo()" :loading="loading">Guardar</v-btn>
                        <v-btn small text outlined @click="closeDialog()" :loading="loading">Salir</v-btn>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <div v-if="hayCorreo">
            <v-row no-gutters justify="end">
                <v-btn v-if="!consejo.constanciasEnviadas" color="primary" dark small @click.stop="dialog = true">
                    <v-icon left small>mdi-mail</v-icon>Modificar correo
                </v-btn>
                <v-alert v-else type="success" dense text>Las constancias ya fueron enviadas</v-alert>
            </v-row>
            <div class="pa-5 pt-0">
                <h1 class="text-center mb-3">Vista Previa</h1>
                <span class="subtitle-1 grey--text text--darken-1">Asunto <br /></span><span
                    v-html="correoEstatico.asunto"></span>
                <v-divider class="my-4"></v-divider>
                <span class="subtitle-1 grey--text text--darken-1">Cuerpo </span><span
                    v-html="correoEstatico.cuerpo"></span>
                <v-divider class="my-4"></v-divider>
                <span class="subtitle-1 grey--text text--darken-1">Despedida <br /></span><span
                    v-html="correoEstatico.despedida"></span>
            </div>
        </div>
        <v-sheet v-else elevation="12" max-width="400" rounded="lg" width="100%"
            class="pa-4 text-center mx-auto mb-10 mt-5">
            <v-icon class="mb-2" color="warning" icon="mdi-check" size="112">mdi-email-remove</v-icon>
            <p class="title">Debes de añadir un correo para poder generar constancias</p>
            <v-btn color="primary lighten-1" dark small @click.stop="dialog = true">
                <v-icon left small>mdi-mail</v-icon>{{ hayCorreo ? "MODIFICAR" : "AÑADIR" }} correo
            </v-btn>
        </v-sheet>
    </div>
</template>

<script>
import { modificarCorreoService } from './consejos.service';

export default {
    name: 'SesionesEditarCorreo',
    props: ['consejo'],
    computed: {
        liga() {
            return this.correo.cuerpo.includes('</a>');
        },
    },
    beforeMount() {
        if (this.consejo.correo?.asunto != null) {
            this.hayCorreo = true;
            this.correoEstatico = Object.assign({}, this.consejo.correo)
            this.correo = Object.assign({}, this.consejo.correo)
        }
    },

    data() {
        return {
            dialog: false,
            content: 'knjgubl',
            customToolbar: [
                [{ 'size': ['small', false, 'large', 'huge'] }],
                [{ 'header': [false, 1, 2, 3, 4, 5, 6,] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'color': [] }, { 'background': [] }],
                ['clean'], ['link']
            ],
            correoEstatico: {
                asunto: '',
                cuerpo: '',
                despedida: '',
            },
            correo: {
                asunto: '',
                cuerpo: '',
                despedida: '',
            },
            valido: false,
            reglasVtext: [
                v => !!v || 'Campo requerido',
            ],
            cuerpo: '',
            loading: false,
            hayCorreo: false
        };
    },
    methods: {
        async modificarCorreoConsejo() {
            try {
                this.loading = true;
                await modificarCorreoService(this.correo, this.consejo._id);
                this.$emit('modificarCorreo', this.correo)
                this.correoEstatico = Object.assign({}, this.correo);
                this.hayCorreo = true;
                this.loading = false;
                this.dialog = false;
                this.$notificarSuccess('Correo añadido correctamente')
            } catch (error) {
                this.loading = false;
                this.$notificarError(error);
            }
        },
        closeDialog() {
            this.correo = Object.assign({}, this.correoEstatico);
            this.dialog = false;
        }
    },
};
</script>

<style lang="css" scoped></style>