import { putServerRequest, getServerRequest, patchServerRequest } from '../../helpers/serverRequest';
import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export async function getDependenciasService() {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/dependencias`;
    return await getServerRequest(url, config);
}

export async function agregarDependenciaService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/dependencias/dependencia`;
    return await putServerRequest(url, data,config);
}

export async function actualizarNombreService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/dependencias/nombre`;
    return await patchServerRequest(url, data, config);
}